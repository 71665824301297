import React, { useContext, useState } from 'react';
import { fromByteArray } from 'base64-js';
import { WithContext as ReactTags } from 'react-tag-input';
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import produce from 'immer';
// import { Document, Page } from "react-pdf";
// import * as pdfjs from 'pdfjs-dist';
// import pdfjsWorker from "react-pdf/node_modules/pdfjs-dist/build/pdf.worker.entry";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { AuthContext } from '../../context/auth';
import { USER_TYPE } from '../../defs/User';
import { EDITOR_TYPE } from '../../defs/Editor';
import EditableSidebar, { DropdownStyle, DropdownIndicator } from '../LSM/LSMCard/EditableSidebar';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import Popup from './Popup';
import ConfirmPopup from '../Popup/ConfirmPopup';
import EmailPopup from './EmailPopup';
import RequestPrintPopup from './RequestPrintPopup';
import ReplacePopup from './ReplacePopup';
import { useMutation } from '@apollo/client';
import GQL from '../../gql';
import PDFViewer from '../PDF/PDFViewer';
import PDFAutoFieldEditor from '../PDF/PDFAutoFieldEditor';
import useFetch from '../../hooks/useFetch';
import Button from '../Button/Button';
import TabContainer, { Tab } from '../TabContainer/TabContainer';
import { isAudio, isVideo } from '../../utils/File';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import '../TagField/TagField.scss';
import GTM from '../../utils/GTM';
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const KeyCodes = {
    comma: 188,
    space: 32,
    enter: 13,
    tab: 9
};

const delimiters = [
    KeyCodes.comma,
    KeyCodes.enter,
    KeyCodes.space,
    KeyCodes.tab
];

const changeLSMoffersName = (e) => {
    document.querySelector('.brand-assets-c-name').innerText = e.value
}

const changeLSMoffersDate = (e) => {
    document.querySelector('.brand-assets-c-name-date').innerText = e.target.value
}


const sendToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        successNotify('Text has been copied to the clipboard.','lsm_success');
    }
    catch (err) {
        console.error('Failed to copy text: ${err}');
    }
}

const successNotify = (title, id) => toast.success(title, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id
});

const errorNotify = (title, id) => toast.error(title, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id
});

const emailProgress = (promise) => toast.promise(promise, {
    pending: 'Sending email...',
    success: 'Email Sent!',
    error: 'Error sending email!',
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: 'lsm_send_email'
});

const uploadProgress = (promise) => toast.promise(promise, {
    pending: 'Uploading...',
    success: 'Upload Complete!',
    error: 'Error uploading file!',
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: 'lsm_upload_file'
});

const FieldContainer = styled.div`
    width: 100%;
    label {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #171b1e;
    }
`;

const NameFieldInput = styled.input`
    border: 1px solid #eee;
    border-radius: 10px;
    height: 40px;
    box-sizing: border-box;
    padding: 10px 15px 10px 10px;
    outline: none;
    font-size: 16px;
    color: #171b1e;
    width: 98%;
    &:focus {
        outline: 1px solid #4a4e51;
    }
`;

const SocialTextField = styled.textarea`
    width: 100%;
    height: 200px;
    padding: 5px;
`;
const SocialTextFieldStatusBar = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div:nth-child(2) {
        align-self: flex-end;
    }
`;

const SocialText = styled.div`
    padding: 4px;
    border: 1px solid #a4a4a4;
    background-color: #eeecec;
`;

function AssetPopup({
    asset,
    trigger,
    setTrigger,
    style,
    popupStyle,
    closeBtn,
    customClass,
    assetId,
    url,
    enableTags,
    tags,
    onClose,
    onDelete,
    enableDownload,
    enableEmailFile,
    enableEmailPrintQuote,
    enableReplace,
    enableDelete,
    enableSave,
    enableEditName
}) {
    const popupProps = {trigger, setTrigger, style, popupStyle, closeBtn, customClass, onClose};
    const { user } = useContext(AuthContext);
    const [stateAsset, setAsset] = useState(asset);
    const [stateTags, setTags] = useState(tags);
    const [name, setName] = useState(asset.name);
    const [buttonPopupEmail, setButtonPopupEmail] = useState(false);
    const [replaceAssetPopup, setReplaceAssetPopup] = useState(false);
    const [confirmRemoveAsset, setConfirmRemoveAsset] = useState(false);
    const [buttonPopupEmailSOS, setButtonPopupEmailSOS] = useState(false);
    const [showRequestPrintQuote, setShowRequestPrintQuote] = useState(false);
    const [startPDFBytes, setStartPDFBytes] = useState(false);
    const [editingPDFBytes, setEditingPDFBytes] = useState(false);

    const navigateTo = useNavigate();
    const location = useLocation();

    //API Calls
    const { fetcher:emailFile } = useFetch(`${process.env.REACT_APP_API_SERVER}lsm-send-mail`);
    const { fetcher:sendSosEmail } = useFetch(`${process.env.REACT_APP_API_SERVER}send-sos-email`);

    //GraphQL

    const [saveAssetMutation, saveAssetState] = useMutation(GQL.Asset.SAVE_ASSET);
    const [deleteAssetMutation, deleteAssetState] = useMutation(GQL.Asset.DELETE_ASSET);

    const onAssetValueChange = (key, value) => {
        setAsset(prevAsset => {
            return { ...prevAsset, [key]: value };
        });
        // setAsset(prevAsset => {
        //     const keys = key.split('.')
        //     if (keys.length > 1 && prevAsset[keys[0]] === null) {
        //         prevAsset[keys[0]] = {};
        //     }
        //     for (let i = 0; i < keys.length - 1; i++) {

        //     }
        // });
        // setAsset(prevAsset => {
        //     return produce(prevAsset, draft => {
        //         const keys = key.split('.');
        //         if (keys.length > 1 && draft[keys[0]] === null) {
        //             draft[keys[0]] = {};
        //         }
        //         let obj = draft;
        //         for (let i = 0; i < keys.length - 1; i++) {
        //             obj = obj[keys[i]] || {};
        //             if (obj === null) {
        //                 obj[keys[i]] = {};
        //                 obj = obj[keys[i]];
        //             }
        //         }
        //         obj[keys[keys.length - 1]] = value;
        //         console.log('obj',obj);
        //     });
        // });
    }

    const onAssetDataValueChange = (key,value) => {
        setAsset(prevAsset => ({
            ...prevAsset,
            data: {
                ...prevAsset.data,
                [key]: value
            }
        }));
    }

    const onDeleteTag = (i) => {
        setTags(stateTags.filter((tag, index) => index !== i));
    };

    const onAddTag = (tag) => {
        setTags([...stateTags, tag.text]);
    };

    const onTagUpdate = (i, newTag) => {
        const updatedTags = stateTags.slice();
        updatedTags.splice(i, 1, newTag.text);
        setTags(updatedTags);
    };

    const onClearAll = () => {
        setTags([]);
    }

    const saveAsset = () => {
        saveAssetMutation(
            {
                variables: {
                    updateAssetInput: {
                        id: assetId,
                        name: stateAsset.name,
                        tags: stateTags,
                        editorType: stateAsset.editorType,
                        data: stateAsset.data
                    }
                },
                onComplete: () => {
                    successNotify('Success','lsm_success');
                },
                onError: (error) => {
                    errorNotify(error, 'lsm_error');
                }
            }
        );
    }

    const onRegenerateThumbnail = async (event) => {
        console.log('onRegenerateThumbnail');
        event.preventDefault();
        event.stopPropagation();

        const formData = new FormData();
        formData.append('assetId', assetId);

        //TODO: Block button.
        
        try {
            console.log('Sending Fetch now...');
            const response = await fetch
            (
                `${process.env.REACT_APP_API_SERVER}thumbnail-regenerate`,
                {
                    method: 'POST',
                    body: formData
                }
            );
            console.log('Fetch done...');
            const jsonResponse = await response.json();
            // console.log(response);
            console.log(jsonResponse);
            successNotify('Success','lsm_success');
        } catch(error) {
            console.error(error);
        }
    }

    const onShowReplaceAssetPopup = (e) => {
        setReplaceAssetPopup(true);
    }

    const onDeleteAsset = (e) => {
        setConfirmRemoveAsset(true);
    }

    const onRemoveAsset = (e) => {
        deleteAssetMutation(
            {
                variables: {
                    assetId
                },
                onCompleted: () => {
                    successNotify('Success','lsm_success');
                    setConfirmRemoveAsset(false);
                    navigateTo(location.pathname.split('/asset/')[0]);
                    setTrigger(false);
                    onDelete();
                },
                onError: (error) => {
                    errorNotify(error, 'lsm_error');
                }
            }
        );
    }

    const downloadFile = (url) => {
        const bytes = fromByteArray((editingPDFBytes || new Uint8Array(startPDFBytes)));
        console.log(bytes);
        const a = document.createElement('a');
        a.href = `data:application/pdf;base64,${bytes}`;
        a.download = `${stateAsset.name}.pdf`;
        a.style.display = 'none';
        document.body.appendChild(a);
        //Activate the download.
        a.click();
        //Cleanup
        setTimeout(() => document.body.removeChild(a), 100);
        //GTM Event
        GTM.event('asset.download',{
            id: stateAsset.id,
            name: stateAsset.name
        });
    }

    const changeQTY = (e) => {
        let value = document.querySelector('#insert-qty').value;
        document.querySelector('.insert-qty').innerText = value;
        setButtonPopupEmailSOS(false);
    }

    const sendEmailFile = (e) => {
        let senderEmail = document.querySelector("#sender-email").value.trim();

        if (senderEmail === '')
        {
            errorNotify('Enter a valid email address.', 'lsm_error');
            return false;
        }

        setButtonPopupEmail(false);
        
        const formData = new FormData();
        formData.append('assetId', assetId);
        formData.append('name', name);
        formData.append('senderEmail', senderEmail);

        if (asset.editorType !== EDITOR_TYPE.DEFAULT
        && asset.editorType !== EDITOR_TYPE.NONE)
        {
            const bytes = editingPDFBytes || startPDFBytes;
            console.log('bytes',bytes);
            formData.append(
                'pdf',
                new Blob
                (
                    [bytes],
                    {
                        type: 'application/pdf'
                    },
                ),
                `${stateAsset.name}.pdf`
            );
        }
        //GTM Event
        GTM.event('asset.email',{
            id: assetId,
            name: name
        });

        emailProgress(emailFile(
            {
                method: 'POST',
                body: formData
            }
        ));
    }

    const onReplaceAsset = (selectedFile) => {
        const formData = new FormData();
        formData.append('assetId', assetId);
        formData.append('pdf', selectedFile);
        formData.append('url', url);
        uploadProgress
        (
            fetch(process.env.REACT_APP_API_SERVER + 'lsm-asset-replace',
            {
                method: 'POST',
                body: formData
            }
            ).then((response) => response.json())
            .then((result) => {
                const loc = location;
                navigateTo('/');
                setTimeout(() => navigateTo(loc),200);
            })
            .catch((error) => {
                console.error('Error:', error);
            })
        );
        setReplaceAssetPopup(false);
        //TODO: Fix this.
        // setShowAssetPopup(false);
    }

    const onSendRequestPrintQuoteEmail = ({
        message,
        deliveryDate,
        store,
        quantity,
        name,
        contactNumber,
        email
    }) => {
        const formData = new FormData();
        formData.append('assetId', assetId);
        formData.append('message', message);
        formData.append('deliveryDate', deliveryDate);
        formData.append('storeAddress', `${store.address}, ${store.suburb}, ${store.state} ${store.pcode}`);
        formData.append('storeId', store.value);
        formData.append('quantity', quantity);
        formData.append('name', name);
        formData.append('contactNumber', contactNumber);
        formData.append('email', email);
        
        if (asset.editorType !== EDITOR_TYPE.DEFAULT
        && asset.editorType !== EDITOR_TYPE.NONE)
        {
            const bytes = editingPDFBytes || startPDFBytes;
            formData.append(
                'pdf',
                new Blob
                (
                    [bytes],
                    {
                        type: 'application/pdf'
                    },
                ),
                `${stateAsset.name}.pdf`
            );
        }
        //GTM Event
        GTM.event('asset.sendToPrint',{
            id: stateAsset.id,
            name: stateAsset.name
        });
        emailProgress
        (
            sendSosEmail({
                method: 'POST',
                headers: {
                    Accept: 'application/json'
                },
                body: formData
            })
        );
    }

    const onEditPDF = (newBytes) => {
        setEditingPDFBytes(newBytes);
    }

    const onChangeSocialText = (e) => {
        console.log(e.target);
        console.log('onChangeSocialText',e.target.value,e.target.value.length);
        onAssetDataValueChange('socialText',e.target.value);
    }

    const onCopySocialText = () => {
        sendToClipboard(stateAsset.data?.socialText);
    }

    const renderSidebar = () => {
        let renderGroup = [];
        switch (asset.editorType)
        {
            case EDITOR_TYPE.DEFAULT:
            {
                if (enableEditName)
                {
                    if (user.userRole === USER_TYPE.ADMIN)
                    {
                        renderGroup.push(
                            <FieldContainer>
                                <label>Name</label>
                                <NameFieldInput value={stateAsset.name} onChange={(e)=>{onAssetValueChange('name',e.target.value)}}></NameFieldInput>
                            </FieldContainer>
                        );
                    }
                }
                if (enableTags && user.userRole === USER_TYPE.ADMIN)
                {
                    const formattedTags = stateTags.map
                    (
                        tag => {
                            return {
                                id: tag,
                                text: tag
                            }
                        }
                    );
                    renderGroup.push(
                        <div className='lsm-popup__sidebar__tag'>
                            <h5>Tags</h5>
                            <ReactTags
                                ReactTagsonAddTag
                                handleDelete={onDeleteTag}
                                handleAddition={onAddTag}
                                delimiters={delimiters}
                                onClearAll={onClearAll}
                                onTagUpdate={onTagUpdate}
                                placeholder="Enter Tags..."
                                minQueryLength={2}
                                maxLength={25}
                                autofocus={false}
                                allowDeleteFromEmptyInput={true}
                                autocomplete={true}
                                readOnly={false}
                                allowUnique={true}
                                allowDragDrop={true}
                                inline={true}
                                allowAdditionFromPaste={true}
                                editable={true}
                                clearAll={true}
                                tags={formattedTags}
                            />
                        </div>
                    );
                }
                if (user.userRole === USER_TYPE.ADMIN)
                {
                    let checked = stateAsset.editorType === 'DEFAULT' ? false:true;
                    renderGroup.push(
                        <FieldContainer>
                            <label>Enable Editable Fields (Requires Save)</label>
                            &nbsp;&nbsp;<input id="isEditable" onChange={(e) => { onAssetValueChange('editorType',e.target.checked ? 'AUTO_FIELD': 'DEFAULT')} } value={1} type="checkbox" checked={checked} />
                            <label htmlFor="isEditable">&nbsp;Yes</label>
                        </FieldContainer>
                    );
                }
                break;
            }
            case EDITOR_TYPE.AUTO_FIELD:
            {
                if (enableEditName)
                {
                    if (user.userRole === USER_TYPE.ADMIN)
                    {
                        renderGroup.push(
                            <FieldContainer>
                                <label>Name</label>
                                <NameFieldInput value={stateAsset.name} onChange={(e)=>{onAssetValueChange('name',e.target.value)}}></NameFieldInput>
                            </FieldContainer>
                        );
                    }
                }
                if (enableTags)
                {
                    if (user.userRole === USER_TYPE.ADMIN)
                    {
                        const formattedTags = stateTags.map
                        (
                            tag => {
                                return {
                                    id: tag,
                                    text: tag
                                }
                            }
                        );
                        renderGroup.push(
                            <div className='lsm-popup__sidebar__tag'>
                                <h5>Tags</h5>
                                <ReactTags
                                    ReactTagsonAddTag
                                    handleDelete={onDeleteTag}
                                    handleAddition={onAddTag}
                                    delimiters={delimiters}
                                    onClearAll={onClearAll}
                                    onTagUpdate={onTagUpdate}
                                    placeholder="Enter Tags..."
                                    minQueryLength={2}
                                    maxLength={25}
                                    autofocus={false}
                                    allowDeleteFromEmptyInput={true}
                                    autocomplete={true}
                                    readOnly={false}
                                    allowUnique={true}
                                    allowDragDrop={true}
                                    inline={true}
                                    allowAdditionFromPaste={true}
                                    editable={true}
                                    clearAll={true}
                                    tags={formattedTags}
                                />
                            </div>
                        );
                    }
                }
                if (user.userRole === USER_TYPE.ADMIN)
                {
                    let checked = stateAsset.editorType === 'DEFAULT' ? false:true;
                    renderGroup.push(
                        <FieldContainer>
                            <label>Enable Editable Fields (Requires Save)</label>
                            &nbsp;&nbsp;<input id="isEditable" onChange={(e) => { onAssetValueChange('editorType',e.target.checked ? 'AUTO_FIELD': 'DEFAULT')} } value={1} type="checkbox" checked={checked} />
                            <label htmlFor="isEditable">&nbsp;Yes</label>
                        </FieldContainer>
                    );
                }
                if (startPDFBytes)
                {
                    renderGroup.push(
                        <PDFAutoFieldEditor
                            startBytes={startPDFBytes}
                            onEdit={onEditPDF}
                        />
                    );
                }
                break;
            }
            // case EDITOR_TYPE.COUPON:
            // {
            //     renderGroup.push
            //     (
            //         <EditableSidebar
            //             storeData={storeNameOptions}
            //             onSelectStore={changeLSMoffersName}
            //             onDateChange={changeLSMoffersDate}
            //         />
            //     );
            //     break;
            // }
            default:
            case EDITOR_TYPE.NONE:
            {
                // renderGroup.push(<p>No Editor Assigned</p>);
                break;
            }
        }

        if (enableEmailFile && !isAudio(url))
        {
            renderGroup.push(<button onClick={() => setButtonPopupEmail(true)} className='btn__email'>EMAIL FILE</button>);
        }
        if (enableEmailPrintQuote && !isAudio(url))
        {
            renderGroup.push(<button onClick={setShowRequestPrintQuote} className='btn__email-sos'>REQUEST PRINT QUOTE</button>);
        }

        if (user.userRole === USER_TYPE.ADMIN)
        {
            if (enableReplace)
            {
                renderGroup.push(
                    <>
                        <button onClick={onShowReplaceAssetPopup} className='btn__replace'>REPLACE ASSET</button>
                        <ReplacePopup
                            popupStyle={{zIndex:10}}
                            trigger={replaceAssetPopup}
                            setTrigger={setReplaceAssetPopup}
                            onUploadClick={onReplaceAsset}
                        />
                    </>
                );
            }

            if (enableDelete)
            {
                renderGroup.push(
                    <>
                        <button onClick={onDeleteAsset} className='btn__delete'>DELETE ASSET</button>
                        <div className="x-container">
                            <ConfirmPopup
                                popupStyle={{zIndex:10}}
                                trigger={confirmRemoveAsset}
                                setTrigger={setConfirmRemoveAsset}
                                onConfirmButtonClick={onRemoveAsset}
                            >
                                <p>Are you sure you want to delete this asset?</p>
                                <p><strong>This action cannot be undone!</strong></p>
                            </ConfirmPopup>
                        </div>
                    </>
                );
            }

            if (enableSave)
            {
                renderGroup.push(<button onClick={saveAsset} className='btn__save full'>SAVE</button>);
            }
        }

        return (
            <div className='lsm-popup__sidebar'>
                {renderGroup}
                <button onClick={() => downloadFile(url)} className='btn__hi-res full'>DOWNLOAD</button>
                {(user.userRole !== USER_TYPE.ADMIN && stateAsset.data?.socialText) && (
                    <FieldContainer>
                        <label>Social Media Copy</label>
                        <SocialText>
                            {stateAsset.data?.socialText}
                        </SocialText>
                        <Button onClick={onCopySocialText} style={{marginTop:'4px',float:'right'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                            </svg>
                            <span>Copy</span>
                        </Button>
                    </FieldContainer>
                )}
                {user.userRole === USER_TYPE.ADMIN && (
                    <button type="button" onClick={(e) => onRegenerateThumbnail(e)} className='btn__hi-res full'>REGENERATE THUMBNAIL</button>
                )}
            </div>
        );
    }
    return (
        <Popup {...popupProps} style={{height:'750px', maxWidth:'1125px'}}>
            <div className='lsm-popup'>
                <div className='lsm-popup__content'>
                    <h5>{stateAsset.name}</h5>
                    {user.userRole !== USER_TYPE.ADMIN && (
                        () => {
                            if (isAudio(url))
                            {
                                return (
                                    <div className='lsm-popup__content--audio'>
                                        <audio src={url} controls />
                                    </div>
                                )
                            }
                            else if (isVideo(url))
                            {
                                const ext = url.split('.').pop();
                                return (
                                    <div className='lsm-popup__content--video'>
                                        <video controls autoPlay loop width="720">
                                            <source src={url} type={`video/${ext}`} />
                                        </video>
                                    </div>
                                )
                            }
                            else if (url.split('.').pop() === 'pdf')
                            {
                                if (asset.editorType === EDITOR_TYPE.AUTO_FIELD
                                && editingPDFBytes)
                                {
                                    return <PDFViewer bytes={editingPDFBytes} />;
                                }
                                else
                                {
                                    return <PDFViewer url={url} onBytesLoaded={(bytes) => setStartPDFBytes(bytes)} />;
                                }
                            }
                            else
                            {
                                return (
                                    <img alt="LSM" src={url} />
                                )
                            }
                        })()
                    }
                    {user.userRole === USER_TYPE.ADMIN && (
                        <TabContainer>
                            <Tab title="Asset View" active={true}>
                                {(() => {
                                    if (isAudio(url))
                                    {
                                        return (
                                            <div className='lsm-popup__content--audio'>
                                                <audio src={url} controls />
                                            </div>
                                        )
                                    }
                                    else if (isVideo(url))
                                    {
                                        const ext = url.split('.').pop();
                                        return (
                                            <div className='lsm-popup__content--video'>
                                                <video controls autoPlay loop width="720">
                                                    <source src={url} type={`video/${ext}`} />
                                                </video>
                                            </div>
                                        )
                                    }
                                    else if (url.split('.').pop() === 'pdf')
                                    {
                                        if (asset.editorType === EDITOR_TYPE.AUTO_FIELD
                                        && editingPDFBytes)
                                        {
                                            return <PDFViewer bytes={editingPDFBytes} />;
                                        }
                                        else
                                        {
                                            return <PDFViewer url={url} onBytesLoaded={(bytes) => setStartPDFBytes(bytes)} />;
                                        }
                                    }
                                    else
                                    {
                                        return (
                                            <img alt="LSM" src={url} />
                                        )
                                    }
                                })()}
                            </Tab>
                            <Tab title="Social Media Copy">
                                {user.userRole === USER_TYPE.ADMIN ? (
                                    <SocialTextField
                                        defaultValue={stateAsset.data?.socialText}
                                        onChange={onChangeSocialText}
                                    />
                                ) : (
                                <>{stateAsset.data?.socialText}</>
                                )}
                                <SocialTextFieldStatusBar>
                                    <div>
                                        <p>Character Count: <strong style={{color: stateAsset.data?.socialText?.length > 560 ? '#fa3535' : stateAsset.data?.socialText?.length > 280 ? 'orange' : '#000'}}>
                                            {stateAsset.data?.socialText?.length}
                                        </strong></p>
                                    </div>
                                    <div>
                                        <Button onClick={onCopySocialText}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                            </svg>
                                            <span>Copy</span>
                                        </Button>
                                    </div>
                                </SocialTextFieldStatusBar>
                            </Tab>
                        </TabContainer>
                    )}
                </div>
                {renderSidebar()}
                <EmailPopup
                    popupStyle={{zIndex:10}}
                    trigger={buttonPopupEmail}
                    setTrigger={setButtonPopupEmail}
                    onSendEmail={sendEmailFile}
                />
                <RequestPrintPopup
                    popupStyle={{zIndex:10}}
                    trigger={showRequestPrintQuote}
                    setTrigger={setShowRequestPrintQuote}
                    onSendEmail={onSendRequestPrintQuoteEmail}
                />
            </div>
        </Popup>
    );
}

export default AssetPopup;